import React from 'react';
import withGroupsListContext from '../../contexts/withGroupsListContext';
import withGroupsActions, {
  WithGroupsActionsProps,
} from '../../contexts/withGroupsActions';

import { DATA_HOOKS } from './dataHooks';

import { WithAppToastsProps } from '../../../../common/components/AppToats/withAppToastsProps';

import { UpdateProgress } from '../../../../common/ContentEditor/UpdateProgress';
import { compose } from '../../../../common/utils/compose';
import { withAppToasts } from '../../../../common/components/AppToats/withAppToasts';
import { withTPAConfig } from '../../../../common/components/withTPAConfig';
import {
  InjectedBiLoggerProps,
  withBi,
  withTranslation,
  WithTranslation,
  withExperiments,
  InjectedExperimentsProps,
} from '@wix/yoshi-flow-editor';
import { Spinner } from '../../../../common/components/Spinner/Spinner';
import { default as CreateGroupModal } from '../modals/CreateGroupModal/CreateGroupModal';
import { GroupList } from '../../../../common/components/GroupList/GroupList';
import {
  WithSettingsProps,
  withSettings,
  WithStylesProps,
  withStyles,
} from '@wix/yoshi-flow-editor/tpa-settings/react';
import { groupsPageView } from '@wix/bi-logger-groups/v2';
import { IGroupsListContext } from '../../../../common/context/groups-list/IGroupsListContext';
import { IUserContext } from '../../../../common/context/user/IUserContext';
import { withUser } from '../../../../common/context/user/withUser';
import { UserExceptionBoundary } from '../../../../common/components/UserExceptionBoundary/UserExceptionBoundary';

export interface GroupsProps {}

export interface GroupsState {
  isCreateGroupModalOpened: boolean;
  isGroupCreating: boolean;
}

export type ComponentProps = GroupsProps &
  IGroupsListContext &
  IUserContext &
  WithGroupsActionsProps &
  WithAppToastsProps &
  WithStylesProps &
  WithSettingsProps & { mobile: boolean } & InjectedBiLoggerProps &
  InjectedExperimentsProps &
  WithTranslation;

export class GroupsSectionComponent extends React.Component<
  ComponentProps,
  GroupsState
> {
  readonly state: GroupsState = {
    isCreateGroupModalOpened: false,
    isGroupCreating: false,
  };

  componentDidMount() {
    this.props.bi.report(
      groupsPageView({
        origin: 'list view',
      }),
    );
  }

  closeCreateGroupModal = () => {
    this.setState({ isCreateGroupModalOpened: false });
  };

  openCreateGroupModal = () => {
    this.setState({ isCreateGroupModalOpened: true });
  };

  render() {
    const { actions, mobile, updateProgress, t } = this.props;

    if (updateProgress === UpdateProgress.UPDATING) {
      return <Spinner offset="L" label={t('groups-web.group-list.loading')} />;
    }
    const { isCreateGroupModalOpened } = this.state;

    return (
      <section key="groups">
        <GroupList
          data-hook={DATA_HOOKS.groupList}
          onCreateGroupClick={this.openCreateGroupModal}
          goToGroup={actions ? actions.goToGroup : undefined}
          mobile={mobile}
        />
        <CreateGroupModal
          isOpen={isCreateGroupModalOpened}
          createGroup={actions && actions.createGroup}
          onClose={this.closeCreateGroupModal}
        />
        <UserExceptionBoundary />
      </section>
    );
  }
}

const enhance = compose(
  withGroupsListContext,
  withGroupsActions,
  withAppToasts,
  withUser,
  withSettings,
  withStyles,
  withTPAConfig,
  withBi,
  withExperiments,
  withTranslation(),
);
export const GroupsSection = enhance(
  GroupsSectionComponent,
) as React.ComponentType<GroupsProps>;
